/* #mainContent{
  width: 100%;
  height: 100%;
  background-image: url("../images/background.jpg");
  background-size: cover;
  position: absolute;
} */

/* .backgroundImageContainer{
  position: absolute;
  top:0;
  left:0;
  bottom:calc(0px - 5em);
  right:0;
  z-index: -1;
  overflow-x: hidden;
  overflow-y: hidden;
} */

.victory-scratcher-heading {
    padding: 10px 10px 10px 10px;
    width: 100%;
    position: relative;
}

.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 1000ms ease-in;
}

.victory-scratcher-heading-image-container {
    height: 70px;
    text-align: center;
}

.victory-scratcher-heading-text-container {
    position: absolute;
    width: 100%;
    left: 0px;
    text-align: center;
    margin-top: 20px;
}

.victory-scratcher-heading-image {
    width: 80%;
    margin-top: 14px;
}

.victory-scratcher-heading-text {
    color: white;
    padding: 0px 0px 0px 0px;
    word-break: break-word;
    line-height: 1;
    font-weight: normal;
    text-align: center;
    text-decoration: underline;
    letter-spacing: 0px;
    -webkit-font-smoothing: antialiased;
    font-size: 22px;
}


@media screen and (max-width: 320px) {
    .victory-scratcher-heading-text-container {
        padding-left: 50px;
    }
}
@media screen and (min-width: 320px) {
    .victory-scratcher-heading-text-container {
        padding-left: 60px;
    }
}
@media screen and (min-width: 520px) {
    .victory-scratcher-heading-text-container {
        padding-left: 0px;
    }
}

.knicks-backImage {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
}

.scratch-subtitle-normal {
    color: white;
    text-align: center;
    font-weight: 200;
    font-size: 22px;
    letter-spacing: 0px;
    -webkit-font-smoothing: antialiased;
}

.scratch-image-container {
    display: inline-block;
    text-align: left;
    border-radius: 10px;
    overflow: hidden;
}

.scratch-image {
/*    width: 100%;
    height: 100%;*/
    display: table;
    position: relative;
}

.scratch-image-subcontainer {
    vertical-align: middle;
    display: table-cell;
    text-align: center;
}

.scratch-image-control {
    margin-top: 50px;
    max-width: 400px;
    max-height: 300px;
}

.cover-image-container {
    left: 0;
    top: 0;
}

.cover-image-title-container {
    position: relative;
    padding: 10px;
    width: 100%;
}

.cover-image-title-background {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #FF0000;
    opacity: 0.5;
}

.cover-image-title-label {
    color: white;
    font-size: 36px;
    position: relative;
    padding: 0px;
    margin: 0px;
    font-weight: 300;
    line-height: 1;
    text-shadow: 2px 2px 14px black;
    letter-spacing: 0px;
    -webkit-font-smoothing: antialiased;
}

.cover-image-control {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}

.scratch-me-paragraph {
    color: white;
    font-size: 30px;
    text-align: center;
    font-weight: 300;
    letter-spacing: 0px;
    -webkit-font-smoothing: antialiased;
}

.scratch-contents {
    width: 100%;
    height: 100%;
}

.winning-container {
    padding: 20px 20px 90px 20px;
}

.winning-container-white {
    padding: 10px 10px 30px 10px;
    margin: 0px 20px 0px 0px;
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    text-align: center;
}

.winning-image-container {
    width: 100%;
    height: 80px;
    margin-top: 10px;
}

.winnning-main-title {
    margin: 0px;
    padding: 20px 10px 0px 10px;
    color: black;
    font-size: 50px;
    font-weight: normal;
    line-height: 1;
    word-break: break-all;
}

.winning-underline-container {
    display: inline-block;
}

.winning-prizes {
    margin: 0px;
    padding: 10px 0px 0px 0px;
    color: black;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    word-break: break-word;
}

.winning-underline {
    margin-top: 4px;
    background-color: black;
    height: 3px;
}

.winning-prize-description {
    margin: 20px 0px 0px 0px;
    color: black;
    font-size: 22px;
    font-weight: normal;
    line-height: 1;
    word-break: break-word;
}

.winning-liveplay-button {
    position: fixed;
    width: 100%;
    bottom: 0px;
    height: 70px;
    background-color: #FF6F00;
    text-align: center;
    padding: 10px;
    display: inline-block;
}

.winning-liveplay-button-image {
    height: 100%;
    display: inline-block;
}

.winning-liveplay-button-text-container {
    display: inline-block;
    vertical-align: middle;
}

.winning-liveplay-button-text {
    color: white;
    font-size: 40px;
    font-weight: normal;
    padding: 0px 0px 0px 10px;
    margin: 0px;
    line-height: 1;
}

.ScratchCard__Container {
    border-radius: 10px;
    overflow: hidden;
}
