/*@import url('https://fonts.googleapis.com/css?family=Oswald:300,600&display=swap');*/
/* Grid version commented out */

/* .grid-container-login {
    display: grid;
    background-image: linear-gradient(to bottom, #111111 0%, #1C2025 100%);
    background-attachment:fixed;
    overflow: auto;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    grid-template-rows: 2fr 1.5fr auto;
    -ms-grid-rows: 2fr 1.5fr auto;
    grid-template-columns: 100%;
}

.grid-item-login {
    margin: auto;
} */

/* .grid-item-login.grid-item-button {
    width: 100%;
    grid-row-start: 3;
    grid-row-end: 4;
    -ms-grid-row: 3 / 4;
} */


/* Flexbox version */

.main-container-login {
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: auto;
}

.input-group-text{
  min-width: 85px
}

.btn-cancel {
  width: auto !important;
  font-size: 0.9rem !important;
  height: 2em !important;
  line-height: normal !important;
  text-transform: none !important;
  min-width: 40%;
}

.content-container-login {
    display: flex;
    flex-direction: column;
    flex: auto;
    overflow: hidden;
    overscroll-behavior: contain;
    margin-bottom: 50px;
}

.login-logo {
    text-align: center;
    margin: auto;
}

.login-logo img {
    width: 70%;
    max-width: 400px;
}

@media (min-width: 767px) {
    .login-main-title {
        font-size: 5em;
    }
    .login-logo img {
        width: 100%;
    }

}

.button-container-login {
    padding: 18px 0;
    background-color: #0854AD;
    width: 100%;
}

.auth-buttons {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    outline-width: 0px;
}
